import { useEffect, useState } from "react";
import { useNavigate, useLocation, Link } from "react-router-dom";
import { MdOutlineLogout } from "react-icons/md";
import {
  Box,
  Accordion,
  AccordionItem,
  AccordionButton,
  AccordionIcon,
  AccordionPanel,
  Flex,
  Text,
  Spacer
} from "@chakra-ui/react";
import { signOut } from "@/contexts";
import styles from "./styles.module.scss";
import { RoutesInterface } from "@/models";
import { isExactFeatureToggle, isExactRole } from "@/utils";
import { RoutesList } from "@/Routes";

const Sidebar = () => {
  const { pathname } = useLocation();
  const navigate = useNavigate();
  const featureTogglesUser = JSON.parse(localStorage.getItem("barte-features-list-user"));
  const [defaultIndex, setDefaultIndex] = useState();
  const [routesApp, setRoutesApp] = useState<RoutesInterface[]>([]);
  const user = JSON.parse(localStorage.getItem("barte-user-info"));
  const Logout = (e) => {
    e.preventDefault();
    signOut();
    navigate("/login");
  };
  const exactMatchFeatureToggles = (item) => item.isShowMenu && isExactRole(user?.roles, item?.role) && isExactFeatureToggle(featureTogglesUser, item?.feature);
  const getRoutesFiltered = (arr) => {
    return arr.reduce((acc, item) => {
      const newItem = item;
      if (item?.items) {
        newItem.items = getRoutesFiltered(item?.items);
      }
      if (exactMatchFeatureToggles(newItem)) {
        acc.push(newItem);
      }
      return acc;
    }, []);
  };

  const getDefaultIndex = (RoutesFiltered) => {
    return RoutesFiltered.filter((item) => item.isShowMenu).reduce(
      (acc, { defaultIsOpen }, index) => {
        if (defaultIsOpen) {
          acc.push(index);
        }
        return acc;
      },
      [] as number[]
    );
  };
  useEffect(() => {
    let RoutesFiltered = getRoutesFiltered(RoutesList);
    setRoutesApp(RoutesFiltered);
    let defaultIndexFiltered = getDefaultIndex(RoutesFiltered);
    setDefaultIndex(defaultIndexFiltered);
  }, []);
  return (
    <Box
      borderRight="1px"
      borderColor="#f0f0f0"
      position="relative"
      w={{ base: "230px", "2xl": "270px" }}

    >
        <Flex
          position="fixed"
          gridArea="S"
          borderRight="1px"
          borderColor="#f0f0f0"
          height="100vh"
          direction="column"
          bgColor="gray.24"
          w={{ base: "230px", "2xl": "270px" }}
          overflowY="auto"
          overflowX="hidden"
        >
          <Flex
            w="100%"
            p=".84rem 1rem"
            borderBottom="1px solid #E6E9F0"
          >
            <img
              src={`/images/commons/logo-${import.meta.env.VITE_APPLICATION_NAME}.svg`}
              alt="Logo"
              width="75.64px"
              height="24px"
            />
          </Flex>
          <Accordion allowMultiple>
            {routesApp
              ?.filter((item) => item?.isShowMenu)
              .map(({ key, icon, path, detail, title, items, isNew }) => (
                <AccordionItem
                  p="0 .4rem"
                  border="none"
                  my={3}
                  key={key}
                >
                  <AccordionButton
                    id={key === 14 ? "click_menu_antecipar" : ""}
                    borderRadius="5"
                    bgColor={pathname === path ? "pink.50" : pathname}
                    onClick={() => navigate(path)}
                    _hover={{
                      backgroundColor: "pink.50",
                      fontcolor: "pink.200",
                      color: "pink.200",
                    }}
                    onMouseDown={(e) => {
                      if(items?.length > 0) return
                      if (e.button === 1) {
                        e.preventDefault();
                        window.open(path, "_blank");
                      }
                    }}
                  >
                    <Flex
                      key={title}
                      align={"center"}
                      gridGap="2"
                      w="100%"
                      minW="270px"
                      _hover={{
                        fontcolor: "pink.200",
                        color: "pink.200",
                      }}
                    >
                      <img
                        src={`/images/icons/${icon}`}
                        className="ant-menu-item-icon"
                        width="25"
                        height="25"
                        style={{
                          color: "#CB2159"
                        }}
                      />
                      {path ? (
                          <Flex 
                            gap="2"
                          >
                            <Text
                              color={
                                (pathname === path ||
                                  pathname.startsWith(detail)) ?
                                "pink.200" : "inherit"
                              }
                              
                            >
                              {title}
                            </Text>
                            {isNew && <Text padding="0px 6px 0px 6px" fontWeight="400" borderRadius="10px" lineHeight="24px" fontSize="10px" backgroundColor="rgba(195, 202, 215, 1)" color="black">beta</Text>}
                          </Flex>
                      ) : (
                        <Flex gap="1">
                          <Text
                            color={
                              (pathname === path ||
                                pathname.startsWith(detail)) &&
                              "pink.200"
                            }
                            _hover={{
                              fontcolor: "pink.200",
                              color: "pink.200",
                            }}
                          >
                            {title}
                          </Text>
                          {isNew && <Text color="green.300">(novo)</Text>}
                        </Flex>
                      )}

                      {items && <AccordionIcon />}
                    </Flex>
                  </AccordionButton>
                  {items && (
                    <>
                      {items.map(({ key, path, title }) => (
                        <AccordionPanel
                          borderRadius="5"
                          m=".2rem 0"
                          key={key}
                          py={2}
                          bgColor={
                            pathname.includes(path) ? "pink.50" : pathname
                          }
                          onClick={() => navigate(path)}
                          color={pathname.includes(path) && "pink.200"}
                          onMouseDown={(e) => {
                            if (e.button === 1) {
                              e.preventDefault();
                              window.open(path, "_blank");
                            }
                          }}
                          _hover={{
                            backgroundColor: "pink.50",
                            cursor: "pointer",
                            fontcolor: "pink.200",
                            color: "pink.200",
                            "div > div": {
                              backgroundColor: "pink.200"
                            }
                          }}
                        >
                          <Flex align="center" w="100%">
                            <Box
                              className="roundDot"
                              borderRadius="full"
                              w="6px"
                              h="6px"
                              mr="4"
                              backgroundColor={pathname.includes(path) ? "pink.200" : "gray.50"}
                            />
                            <Text
                              color="inherit"
                            >
                              {title}
                            </Text>
                          </Flex>
                        </AccordionPanel>
                      ))}
                    </>
                  )}
                </AccordionItem>
              ))}
          </Accordion>
          <Spacer />
          <Accordion>
            <AccordionItem 
              border="none" 
              w="100%"
            >
              <AccordionButton 
                onClick={(e) => Logout(e)}
                _hover={{
                  backgroundColor: "pink.50",
                  fontcolor: "pink.200",
                  color: "pink.200",
                }}
              >
                <Flex
                  align="center"
                  gridGap="2"
                  color="inherit"
                >
                  <MdOutlineLogout
                    size={23}
                    className={`${pathname == "/" && styles.navActive} ${
                      styles.navLink
                    }`}
                  />
                 
                  Sair
                </Flex>
              </AccordionButton>
            </AccordionItem>
          </Accordion>
        </Flex>
    </Box>
  );
};
export { Sidebar };
